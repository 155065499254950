// Art Data - Import images and metadata
const artImagesContext = require.context(
  '../assets/images/Art',
  true,
  /\.(png|jpe?g|gif|webp)$/
);
const artMetadataContext = require.context(
  '../assets/images/Art',
  true,
  /metadata\.json$/
);

// Helper function to import all files from a context
const importAll = (context: __WebpackModuleApi.RequireContext) =>
  context.keys().map((key) => ({
    path: key,
    data: context(key),
  }));

// Load images and metadata
const artImages = importAll(artImagesContext);
const artMetadata = importAll(artMetadataContext);

// Combine images and metadata using the id from the metadata.json file
export const ArtData = artMetadata
  .filter((meta) => {
    const folderName = meta.path.split('/')[meta.path.split('/').length - 2]; // Still using folder name to load images
    const imagesForFolder = artImages.filter((image) =>
      image.path.includes(folderName)
    );

    // Only include folders with at least one image
    return imagesForFolder.length > 0;
  })
  .map((meta) => {
    const folderName = meta.path.split('/')[meta.path.split('/').length - 2];
    const imagesForFolder = artImages.filter((image) =>
      image.path.includes(folderName)
    );

    // Use the mainImage if specified, otherwise fallback to the first image
    const mainImage = meta.data.mainImage
      ? imagesForFolder.find((image) =>
          image.path.includes(meta.data.mainImage)
        )
      : imagesForFolder[0];

    const defaultMetadata = {
      id: folderName, // Use folder name as fallback id
      title: 'Untitled',
      description: 'No description available.',
      artist: 'Unknown',
      music: '',
    };

    // Merge metadata from the metadata.json file and default metadata
    const metadata = {
      ...defaultMetadata,
      ...meta.data,
    };

    return {
      image: mainImage ? mainImage.data : '', // Display the main image or fallback
      images: imagesForFolder.map((img) => img.data), // Include all images
      metadata, // Include the merged metadata
    };
  });
