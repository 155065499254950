import { Routes } from '../../utils/Routes';

export default function Footer() {
  return (
    <footer className="w-full bg-black text-white px-6 py-8">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center space-y-6 lg:space-y-0">
        {/* Left section: Slogan and creative elements */}
        <div className="flex flex-col items-start space-y-3 lg:space-y-0 lg:items-center lg:flex-row">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-white text-black rounded-full">
              <a
                href={Routes.Home}
                className="hover:text-red-600 transition duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  {/* Eye outline */}
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4.5C7 4.5 2.73 9 2 12s5 7.5 10 7.5 9.27-4.5 10-7.5S17 4.5 12 4.5z"
                  />
                  {/* Pupil */}
                  <circle cx="12" cy="12" r="2.5" fill="black" />
                </svg>
              </a>
            </div>
            <p className="text-xl font-semibold tracking-wide">
              Discover the <span className="text-custom-coffee">unspoken </span>
            </p>
          </div>
          <div className="text-sm text-gray-400 mt-2">
            <p className="hidden lg:block">
              {/* Where art meets sound and thought. */}
            </p>
          </div>
        </div>

        {/* Middle section: Social media links */}
        <div className="flex items-center space-x-6">
          <div className="hover:text-custom-coffee transition duration-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 5.636a9 9 0 11-12.728 0"
              />
            </svg>
          </div>
          <div className="hover:text-custom-coffee transition duration-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8.5l4 4M8 12.5l4 4"
              />
            </svg>
          </div>
          <div className="hover:text-custom-coffee transition duration-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 2v20m8-10H4"
              />
            </svg>
          </div>
        </div>

        {/* Right section: Additional links */}
        <div className="flex flex-col lg:flex-row space-y-2 lg:space-x-8 lg:space-y-0 text-center text-gray-500 text-sm">
          <p>
            &copy; {new Date().getFullYear()} Unspoken Creations. All rights
            reserved.
          </p>
        </div>
      </div>

      {/* Bottom section: Copyright */}
    </footer>
  );
}
