import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../utils/Routes';
import { ArtData } from '../../utils/ArtData';
import ArtPlaceholder from '../../assets/images/Art/ArtPlaceholder.svg';
import SearchBar from '../common/SearchBar';

export default function UnspokenArt() {
  // State to track if an image has been loaded for each item, mapped by the id
  const [loadedImages, setLoadedImages] = useState<{ [id: string]: boolean }>(
    {}
  );

  // State to track the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Handle the image load event for a specific art item
  const handleImageLoad = (id: string) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [id]: true,
    }));
  };

  // Check if images are cached and trigger load if they are
  useEffect(() => {
    ArtData.forEach((art) => {
      const img = new Image();
      img.src = art.image;

      // If the image is cached, treat it as loaded
      if (img.complete) {
        handleImageLoad(art.metadata.id);
      }
    });
  }, []);

  // Filter the ArtData based on the search query
  const filteredArt = ArtData.filter((art) => {
    const lowerCasedQuery = searchQuery.toLowerCase();
    return (
      art.metadata.title.toLowerCase().includes(lowerCasedQuery) ||
      art.metadata.artist?.toLowerCase().includes(lowerCasedQuery)
    );
  });

  // Sort the filteredArt by id in ascending order
  const sortedArt = [...filteredArt].sort(
    (a, b) => a.metadata.id - b.metadata.id
  );

  return (
    <div className="container mx-auto mb-8 p-4">
      {/* SearchBar */}
      <div className="mb-6">
        <SearchBar
          query={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Search by title or artist..."
        />
      </div>

      {/* Art Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
        {sortedArt.length > 0 ? (
          sortedArt.map((art) => (
            <div
              key={art.metadata.id}
              className="max-w-sm mx-auto p-4 border border-gray-300 shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
            >
              <Link to={`${Routes.Art}/${art.metadata.id}`}>
                <div className="cursor-pointer w-full h-auto">
                  {/* Show placeholder if image is not loaded */}
                  {!loadedImages[art.metadata.id] && (
                    <img
                      src={ArtPlaceholder}
                      alt="Art Placeholder"
                      className="w-full h-auto rounded-lg hover:border-2 border-gray-300"
                    />
                  )}

                  {/* Main image with fade-in transition */}
                  <img
                    src={art.image}
                    alt={`${art.metadata.title} Art Piece`}
                    className={`w-full h-auto rounded-lg hover:border-2 border-gray-300 transition-opacity duration-500 ${
                      loadedImages[art.metadata.id] ? 'block' : 'hidden'
                    }`}
                    onLoad={() => handleImageLoad(art.metadata.id)}
                    onError={() => handleImageLoad(art.metadata.id)}
                    loading="lazy"
                  />
                </div>
              </Link>

              <div className="mt-4 text-center">
                <Link to={`${Routes.Art}/${art.metadata.id}`}>
                  <h2 className="text-xl font-semibold text-custom-coffee cursor-pointer hover:underline">
                    {art.metadata.title || 'Untitled'}
                  </h2>
                </Link>
                <h3 className="text-md text-gray-500 italic">
                  {art.metadata.artist || 'Anonymous'}
                </h3>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 col-span-full">
            No results found for "{searchQuery}"
          </div>
        )}
      </div>
    </div>
  );
}
