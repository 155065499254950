export const Routes = {
  Home: '/',
  About: '/about',
  Art: '/art',
  ArtDetails: '/art/:id',
  Music: '/music',
  Thoughts: '/thoughts',
  Contact: '/contact',
  NotFound: '/404',
};
