import LayOut from '../components/common/Layout';
import SubHeading from '../components/common/SubHeading';

export default function Thoughts() {
  return (
    <LayOut>
      <SubHeading text="Under The Surface" customStyle="py-20" />
    </LayOut>
  );
}
