import React from 'react';

interface ButtonProps {
  label: string;
  onClick?: () => void;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  className = '',
}) => {
  return (
    <button
      className={`px-6 py-3 rounded-lg shadow-md font-semibold focus:outline-none 
        transition duration-300 ease-in-out text-xl border-2 border-gray-300 hover:bg-slate-700 text-white hover:bg-opacity-60 hover:text-gray-300 ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
