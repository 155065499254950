import { useState } from 'react';
import axios from 'axios';
import LayOut from '../components/common/Layout';
import Heading from '../components/common/Heading';
import { APIs } from '../utils/Constants';

export default function Contact() {
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [hasTyped, setHasTyped] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true when submitting
    setError(''); // Clear any previous errors
    setValidationError(''); // Clear validation errors

    try {
      await axios.post(
        process.env.REACT_APP_UNSPOKEN_SERVICES_API + APIs.message,
        {
          message,
        }
      );
    } catch (err: any) {
      if (err.response && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setSubmitted(true);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setMessage('');
    setError('');
    setValidationError('');
    setSubmitted(false);
    setHasTyped(false);
  };

  const validateMessage = (msg: string) => {
    if (msg.length < 5) {
      return 'Message is too short. Minimum 5 characters required.';
    }
    if (msg.length > 1000) {
      return 'Message is too long. Maximum 1000 characters allowed.';
    }
    if (msg.toLowerCase().includes('<script>')) {
      return 'Invalid content (HTML tags not allowed).';
    }
    return '';
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setMessage(newValue);
    setHasTyped(true);
    const errorMsg = validateMessage(newValue);
    setValidationError(errorMsg);
  };

  return (
    <LayOut>
      <div className="container mx-auto my-8 p-4">
        <div className="text-center mb-8">
          <Heading
            text="Reach out to the unspoken"
            customStyle="text-custom-stone text-3xl"
          />
        </div>

        {submitted ? (
          <div className="text-center text-gray-400 text-lg mt-8">
            {error ? (
              <p>
                <i className="text-red-500">{error}</i>
              </p>
            ) : (
              <p>Thank you for your message!</p>
            )}
            <div className="inline-flex border border-gray-400 rounded-lg p-2 mt-4">
              <button
                onClick={handleReset}
                className="text-custom-stone hover:text-gray-200 transition-colors duration-300"
              >
                &#8592; {error ? 'Re-Send Message ' : 'Share more thoughts'}
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
            <textarea
              value={message}
              onChange={handleChange}
              placeholder="Share your thoughts..."
              className={`w-full p-4 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-stone focus:border-transparent placeholder-gray-400 ${
                validationError ? 'border-red-500' : ''
              }`}
              rows={6}
              required
              disabled={isLoading}
            />
            {hasTyped && validationError && (
              <i className="text-red-600 mt-2 text-sm">{validationError}</i>
            )}
            <div className="mt-5">
              <button
                type="submit"
                className={`w-full text-white lg:text-xl p-3 rounded-lg transition duration-500 shadow-lg ${
                  validationError || isLoading
                    ? 'bg-gray-500 cursor-not-allowed'
                    : 'bg-custom-coffee hover:bg-gray-800'
                }`}
                disabled={isLoading || !!validationError}
              >
                {isLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        )}
      </div>
    </LayOut>
  );
}
