import LayOut from '../components/common/Layout';
import backgroundImage from '../assets/images/background_image.jpg';
import { Button } from '../components/common/Button';
import { Link } from 'react-router-dom';
import { Routes } from '../utils/Routes';
import Heading from '../components/common/Heading';
import { useEffect, useState } from 'react';

export default function Home() {
  const [showSpecialMessage, setShowSpecialMessage] = useState(false);
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`; // Format the time as HH:MM

    // Set the current time to state
    setCurrentTime(formattedTime);

    // Check if the current time is between 3:00 AM and 3:15 AM
    if (hours === 3 && minutes >= 0 && minutes < 15) {
      setShowSpecialMessage(true);
    } else {
      setShowSpecialMessage(false);
    }
  }, []);

  return (
    <LayOut>
      <div
        className="py-16 md:min-h-[420px] 2xl:min-h-[900px] bg-cover bg-center bg-no-repeat flex justify-center items-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="flex flex-col items-center gap-5 text-white">
          <div>
            <Heading text="Welcome to Unspoken Creations!" />
            <p className="mb-6 text-lg text-white">
              Stories that words can't tell . . .
            </p>
            <i className="text-transparent">
              Discover the unseen, hear the unspoken, feel the silence.
            </i>
            {/* Conditionally render special message */}
            {showSpecialMessage && (
              <div className="mt-5 text-black font-bold text-lg italic">
                It's {currentTime} Why are you still awake?
              </div>
            )}
          </div>
          <div className="flex flex-col md:flex-row gap-5">
            <Link to={Routes.Art}>
              <Button label="Unspoken Art" />
            </Link>
            <Link to={Routes.Music}>
              <Button label="Unspoken Music" />
            </Link>
          </div>
        </div>
        <br />
      </div>
    </LayOut>
  );
}
