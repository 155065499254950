import LayOut from '../components/common/Layout';
import SubHeading from '../components/common/SubHeading';

export default function NotFound() {
  return (
    <LayOut>
      <SubHeading text="Oooops!!!" customStyle="py-20"/>
    </LayOut>
  );
}
