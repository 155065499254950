export type NavItemProps = { title: string; route: string; className?: string };

export default function NavItem({ title, route, className }: NavItemProps) {
  return (
    <div className={`${className} lg:inline-block`}>
      <a
        href={route}
        className="font-primary leading-primary font-semibold lg:font-normal hover:text-custom-stone transition-colors flex justify-between items-center"
      >
        <span className="text-left">{title}</span>
        <span className="lg:hidden inline-block align-middle text-right"></span>
      </a>
    </div>
  );
}
