import { ReactNode } from 'react';
import Footer from './Footer';
import Header from './Header';

type LayoutProps = {
  children: ReactNode;
  layoutStyle?: string;
};

export default function LayOut({ children, layoutStyle }: LayoutProps) {
  return (
    <div className="mx-auto">
      <Header />
      <div className="bg-black">
        <main
          className={`py-5 md:py-10 mx-auto container text-center px-2 ${layoutStyle}`}
        >
          {/* Note: Add 'min-h-screen' to main className for full screen effect */}
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
}
