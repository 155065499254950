import { useState } from 'react';

interface SearchBarProps {
  query: string;
  onSearch: (query: string) => void;
  placeholder?: string;
}

export default function SearchBar({
  query,
  onSearch,
  placeholder = 'Search...',
}: SearchBarProps) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={`flex items-center w-full border rounded-lg p-2 transition-colors duration-300 ${
        isFocused ? 'border-custom-stone' : 'border-gray-300'
      }`}
    >
      <input
        type="text"
        value={query}
        onChange={(e) => onSearch(e.target.value)}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="flex-grow border-none outline-none"
      />

      {/* Clear Button */}
      {query && (
        <button
          onClick={() => onSearch('')}
          className="ml-2 text-gray-400 hover:text-red-600 focus:outline-none"
        >
          &#10005; {/* The "X" icon */}
        </button>
      )}
    </div>
  );
}
