import { useState, useRef, useEffect } from 'react';

interface MusicListProps {
  id: number;
  musicFileName: string;
  title?: string;
  artist?: string;
  isPlaying?: boolean;
  isSelected?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
}

export default function MusicList({
  id,
  musicFileName,
  title = 'Untitled Soundtrack',
  artist = 'Unknown Artist',
  isPlaying = false,
  isSelected = false,
  onPlay,
  onPause,
}: MusicListProps) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLooping, setIsLooping] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.addEventListener('timeupdate', () => {
        setCurrentTime(audioElement.currentTime);
      });

      audioElement.addEventListener('loadedmetadata', () => {
        setDuration(audioElement.duration);
      });

      audioElement.addEventListener('ended', () => {
        if (!isLooping) {
          onPause?.();
        }
      });

      if (isPlaying) {
        audioElement.play().catch((error) => console.log(error));
      } else if (isSelected) {
        audioElement.pause();
      } else {
        // If a different song is selected, reset progress and loop
        audioElement.pause();
        audioElement.currentTime = 0;
        setCurrentTime(0);
        setIsLooping(false);
      }
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', () => {
          setCurrentTime(audioElement.currentTime);
        });
        audioElement.removeEventListener('loadedmetadata', () => {
          setDuration(audioElement.duration);
        });
        audioElement.removeEventListener('ended', () => {
          onPause?.();
        });
      }
    };
  }, [isPlaying, isLooping, onPause, isSelected]);

  const togglePlayPause = () => {
    if (isPlaying) {
      onPause?.();
    } else {
      onPlay?.();
    }
  };

  const toggleLoop = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.loop = !audioElement.loop;
      setIsLooping(audioElement.loop);
    }
  };

  const handleProgressClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const progressBarWidth = e.currentTarget.clientWidth;
      const clickPosition = e.nativeEvent.offsetX;
      const newTime = (clickPosition / progressBarWidth) * duration;
      audioElement.currentTime = newTime;
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="p-4 border-b border-gray-800 flex items-center hover:bg-gray-900 transition">
      {/* Play/Pause Button */}
      <div className="w-10 text-gray-400 flex justify-center items-center">
        <button
          onClick={togglePlayPause}
          className="hover:text-gray-500 transition"
        >
          {!isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <polygon points="5 3 19 12 5 21 5 3"></polygon>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <rect x="6" y="4" width="4" height="16"></rect>
              <rect x="14" y="4" width="4" height="16"></rect>
            </svg>
          )}
        </button>
      </div>

      {/* Music Info */}
      <div className="flex-1 pl-4 text-left">
        <p className="text-gray-300 text-lg">
          {title} <span className="text-gray-400"> by </span> {artist}
        </p>

        {/* Progress Bar */}
        {(isPlaying || isSelected) && (
          <>
            <div
              className="w-full h-2 bg-gray-700 mt-2 rounded relative cursor-pointer"
              onClick={handleProgressClick}
            >
              <div
                className="h-2 bg-gray-300 rounded"
                style={{ width: `${(currentTime / duration) * 100}%` }}
              />
            </div>
            {/* Duration */}
            {(isPlaying || isSelected) && (
              <span className="text-gray-400 pl-2">
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            )}
          </>
        )}
      </div>

      {/* Sound Bars */}
      {(isPlaying || isSelected) && (
        <div className="w-12 h-12 flex justify-center items-center">
          <div className="flex space-x-1">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className={`w-2 h-6 bg-gray-400 rounded ${
                  isPlaying ? 'animate-pulse' : ''
                }`} // Animate only when playing
                style={{ animationDuration: `${0.5 + i * 0.1}s` }}
              />
            ))}
          </div>
        </div>
      )}

      {/* Loop Icon */}
      {(isPlaying || isSelected) && (
        <div
          className={`cursor-pointer ${
            isLooping ? 'text-custom-sand' : 'text-gray-500'
          }`}
          onClick={toggleLoop}
          title="Toggle Loop"
        >
          <svg
            className="w-6 h-6"
            viewBox="0 0 30 30"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              d="M23.5 16C22 20 18 23 14 23C8.5 23 4 19 3.3 13.5"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 10C5.5 6 9 2.5 14 2.5C19.5 2.5 24 7 24.5 12"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.5 23L24.5 17.5C24.5 17.2 24.2 17 23.9 17H17"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10.5H4C3.7 10.5 3.5 10.2 3.5 9.5V4"
              stroke="currentColor"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      {/* Audio Element */}
      <audio ref={audioRef} src={`/assets/music/${musicFileName}`} hidden />
    </div>
  );
}
