type HeadingProps = {
  text: string;
  customStyle?: string;
};

export default function Heading({ text, customStyle }: HeadingProps) {
  return (
    <h2 className={`text-2xl text-custom-stone font-bold mb-4  ${customStyle}`}>
      {text}
    </h2>
  );
}
