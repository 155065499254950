import { useEffect, useState, useCallback } from 'react';
import LayOut from '../components/common/Layout';
import Heading from '../components/common/Heading';
import MusicList from '../components/unspokenMusic/MusicList';
import musicMetadata from '../assets/music/metadata.json';

interface MusicData {
  id: number;
  filename: string;
  title?: string;
  artist?: string;
  genre: string;
}

export default function Music() {
  const [musicData, setMusicData] = useState<MusicData[]>([]);
  const [currentPlayingId, setCurrentPlayingId] = useState<number | null>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    setMusicData(musicMetadata);
  }, []);

  // Grouping the music by genre
  const categorizedMusic = musicData.reduce((acc: any, music) => {
    const { genre } = music;
    if (!acc[genre]) {
      acc[genre] = [];
    }
    acc[genre].push(music);
    return acc;
  }, {});

  const handlePlay = (id: number) => {
    if (currentPlayingId !== null && currentPlayingId !== id) {
      // Pause the currently playing song and reset it
      setCurrentPlayingId(null);
      setIsPaused(true); // Pause the currently playing song
    }

    setCurrentPlayingId(id);
    setIsPaused(false); // Start playing the new song
  };

  // Memoize the handlePause function to avoid ESLint dependency warnings
  const handlePause = useCallback(() => {
    setIsPaused(true); // Mark as paused without changing the currently playing song
  }, []);

  return (
    <LayOut layoutStyle="bg-gray-900 text-white">
      {/* Header Section */}
      <div className="text-center pt-20 pb-5">
        <Heading text="Unspoken Music" />
        <p className="text-gray-500 text-lg mx-auto max-w-3xl tracking-wide">
          Soundtracks that reveal the unspoken essence of art.
        </p>
      </div>

      {/* Music grouped by genre */}
      <div className="px-6">
        {Object.keys(categorizedMusic).map((genre) => (
          <div key={genre}>
            <h2 className="text-2xl font-bold text-white pb-4 pt-6 text-left">
              {genre}
              {genre === 'Jazz' && (
                <>
                  <p className="mt-2 text-sm lg:text-base text-custom-olive italic">
                    Listen to full album on Spotify
                  </p>
                  <iframe
                    className="border-radius:12px"
                    src="https://open.spotify.com/embed/album/2sFbHAkPnkFp2jymJuw9L2?utm_source=generator"
                    title="Jazz spotify album embed"
                    width="100%"
                    height="152"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                  ></iframe>
                </>
              )}
            </h2>{' '}
            {/* List of Music Tracks */}
            {categorizedMusic[genre].map((music: MusicData) => (
              <MusicList
                key={music.id}
                id={music.id}
                musicFileName={music.filename}
                title={music.title || 'Untitled Soundtrack'}
                artist={music.artist || 'Unknown Artist'}
                isPlaying={currentPlayingId === music.id && !isPaused}
                isSelected={currentPlayingId === music.id}
                onPlay={() => handlePlay(music.id)}
                onPause={handlePause} // Pass memoized function
              />
            ))}
          </div>
        ))}
      </div>
    </LayOut>
  );
}
