import React from 'react';
import { SalesStatus } from '../../utils/Constants';

type PricingInfoProps = {
  metadata: {
    status: string;
    price?: {
      amount: number;
      currency: string;
    };
  };
};

export const PricingInfo: React.FC<PricingInfoProps> = ({ metadata }) => {
  switch (metadata.status) {
    case SalesStatus.AVAILABLE:
      return (
        <div>
          {(metadata.price?.amount && (
            <>
              <p className="text-custom-olive font-bold text-lg">
                Price: {metadata.price?.amount}{' '}
                {metadata.price?.currency || 'KES'}
              </p>
              <button className="bg-custom-sage hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Buy Artwork
              </button>
            </>
          )) || (
            <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
              Inquire for Price
            </button>
          )}
        </div>
      );

    case SalesStatus.SOLD:
      return <p className="text-red-500 italic">This artwork has been sold.</p>;

    case SalesStatus.NOT_FOR_SALE:
      return (
        <p className="text-gray-400 italic">This artwork is not for sale.</p>
      );

    case SalesStatus.INQUIRE_FOR_PRICE:
      return (
        <div>
          <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
            Inquire for Price
          </button>
        </div>
      );

    case SalesStatus.COMMISSION_AVAILABLE:
      return (
        <div>
          <p className="text-custom-olive font-medium">
            Commissions are available for custom artwork.
          </p>
          <button className="mt-2 bg-custom-sage hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
            Inquire for Commission
          </button>
        </div>
      );

    default:
      return null;
  }
};
